// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Ensure that the root app container takes the full viewport height */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure it covers the entire viewport */
}

/* Ensure the content area grows to take the available space */
.content {
  flex: 1 1; /* Grow to take available space */
  padding: 0;
  margin: 0;
  overflow-y: auto; /* Allow vertical scrolling if content overflows */
  height: 100vh;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100vh;
    background-image: linear-gradient(125deg, red, black, blue);
    background-size: 200%;
    animation: bganimation 15s infinite;
    background-attachment: fixed; /* Keeps the background fixed while scrolling */
}

@keyframes bganimation {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,sEAAsE;AACtE;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB,EAAE,yCAAyC;AAC9D;;AAEA,8DAA8D;AAC9D;EACE,SAAO,EAAE,iCAAiC;EAC1C,UAAU;EACV,SAAS;EACT,gBAAgB,EAAE,kDAAkD;EACpE,aAAa;AACf;;AAEA;EACE,SAAS;EACT,UAAU;EACV,aAAa;IACX,2DAA2D;IAC3D,qBAAqB;IACrB,mCAAmC;IACnC,4BAA4B,EAAE,+CAA+C;AACjF;;AAEA;EACE;IACE,0BAA0B;EAC5B;EACA;IACE,6BAA6B;EAC/B;EACA;IACE,0BAA0B;EAC5B;AACF","sourcesContent":["/* Ensure that the root app container takes the full viewport height */\n.App {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh; /* Ensure it covers the entire viewport */\n}\n\n/* Ensure the content area grows to take the available space */\n.content {\n  flex: 1; /* Grow to take available space */\n  padding: 0;\n  margin: 0;\n  overflow-y: auto; /* Allow vertical scrolling if content overflows */\n  height: 100vh;\n}\n\nhtml, body {\n  margin: 0;\n  padding: 0;\n  height: 100vh;\n    background-image: linear-gradient(125deg, red, black, blue);\n    background-size: 200%;\n    animation: bganimation 15s infinite;\n    background-attachment: fixed; /* Keeps the background fixed while scrolling */\n}\n\n@keyframes bganimation {\n  0% {\n    background-position: 0 50%;\n  }\n  50% {\n    background-position: 100% 50%;\n  }\n  100% {\n    background-position: 0 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
