// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theprojects {
    text-align: center;
    font-family: 'Sanskrit Text', serif;
    font-weight: bold;
    font-size: xx-large;
    color: white;
    padding-bottom: 20px;
}

.column {
    padding-bottom: 1rem;
}

.columntitle {
    text-align: left;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: x-large;
    color: blue;
    padding-bottom: 20px;
}

.summaries {
    text-align: left;
    font-family: 'Sanskrit Text', serif;
    z-index: 1;
    justify-content: center;
    font-size: 1rem;
    color: white;
    padding-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Projects.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mCAAmC;IACnC,iBAAiB;IACjB,mBAAmB;IACnB,YAAY;IACZ,oBAAoB;AACxB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,yCAAyC;IACzC,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,mCAAmC;IACnC,UAAU;IACV,uBAAuB;IACvB,eAAe;IACf,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[".theprojects {\n    text-align: center;\n    font-family: 'Sanskrit Text', serif;\n    font-weight: bold;\n    font-size: xx-large;\n    color: white;\n    padding-bottom: 20px;\n}\n\n.column {\n    padding-bottom: 1rem;\n}\n\n.columntitle {\n    text-align: left;\n    font-family: Arial, Helvetica, sans-serif;\n    font-weight: bold;\n    font-size: x-large;\n    color: blue;\n    padding-bottom: 20px;\n}\n\n.summaries {\n    text-align: left;\n    font-family: 'Sanskrit Text', serif;\n    z-index: 1;\n    justify-content: center;\n    font-size: 1rem;\n    color: white;\n    padding-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
