// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
    color: white;
    text-align: center;
    font-weight: bold;
    font-family: 'Sanskrit Text', serif;
    display: flex;
    flex-direction: column; /* Stack items vertically */
    margin-top: auto; /* Pushes footer to the bottom of .App */
    padding: 40px 0; /* Add padding for spacing */
    width: 100%; /* Ensure it spans the full width */
}

footer a {
    color: white;
}

.footy {
    justify-content: space-between; /* Align items inside footy */
}

/* Remove this ID if you want the footer to behave as part of the document flow */
/* If you want a sticky footer that doesn't overlap content, avoid using fixed position */
#footer {
    /* position: fixed; */ /* Commented out */
    /* bottom: 0; */ /* Commented out */
}`, "",{"version":3,"sources":["webpack://./src/styles/Footer.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,mCAAmC;IACnC,aAAa;IACb,sBAAsB,EAAE,2BAA2B;IACnD,gBAAgB,EAAE,wCAAwC;IAC1D,eAAe,EAAE,4BAA4B;IAC7C,WAAW,EAAE,mCAAmC;AACpD;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,8BAA8B,EAAE,6BAA6B;AACjE;;AAEA,iFAAiF;AACjF,yFAAyF;AACzF;IACI,qBAAqB,EAAE,kBAAkB;IACzC,eAAe,EAAE,kBAAkB;AACvC","sourcesContent":["footer {\n    color: white;\n    text-align: center;\n    font-weight: bold;\n    font-family: 'Sanskrit Text', serif;\n    display: flex;\n    flex-direction: column; /* Stack items vertically */\n    margin-top: auto; /* Pushes footer to the bottom of .App */\n    padding: 40px 0; /* Add padding for spacing */\n    width: 100%; /* Ensure it spans the full width */\n}\n\nfooter a {\n    color: white;\n}\n\n.footy {\n    justify-content: space-between; /* Align items inside footy */\n}\n\n/* Remove this ID if you want the footer to behave as part of the document flow */\n/* If you want a sticky footer that doesn't overlap content, avoid using fixed position */\n#footer {\n    /* position: fixed; */ /* Commented out */\n    /* bottom: 0; */ /* Commented out */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
