import React from "react";
import AnimatedPage from "../components/AnimatedPage";
import '../styles/Contact.css';

const Contact = () => {

  return (
      <div className="contact">
          <AnimatedPage>
            <h1 className="contacttitle">UNDER MAINTENANCE</h1>
              <h2 className="contacttitle">But click on the links below!</h2>
          </AnimatedPage>
      </div>
  )
};

export default Contact;