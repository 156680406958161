import { Outlet, NavLink } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/NavBar.css';
import React from "react";

function NavBar() {
  return (
      <>
        <div className="logo">
          JESUS . CONTACT
        </div>
        <div className="items">
            <nav className="nav navbar-expand-lg justify-content-center">
                <li>
                    <NavLink to="/" className={({isActive}) => isActive ?
                        "navbur active-link" : "navbur"}>Home</NavLink>
                </li>
                <li>
                    <NavLink to="/projects" className={({isActive}) => isActive ?
                        "navbur active-link" : "navbur"}>Projects</NavLink>
                </li>
                <li>
                    <NavLink to="/contact" className={({isActive}) => isActive ?
                        "navbur active-link" : "navbur"}>Contact Me</NavLink>
                </li>
            </nav>
        </div>
          <Outlet/>
      </>
  );
}

export default NavBar;