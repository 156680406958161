import React from "react";
import AnimatedPage from "../components/AnimatedPage";
import Container from "react-bootstrap/Container";
import '../styles/Projects.css';
import {Col, Row} from "react-bootstrap";
import minecraft from '../images/minecraft.png'
import jellyfin from '../images/jellyfin.png'
import cloud from "../images/cloud.png"
import snake from '../images/snake.png'

const Projects = () => {

  return (
      <div className="projects">
          <AnimatedPage>
              <div className="theprojects">
                  Some of my Projects...
              </div>
              <Container className="projsummary">
                <Row>
                    <Col sm={3} className="column">
                        <img className="img-fluid rounded mx-auto d-block" src={minecraft} alt=""/>
                    </Col>
                    <Col sm={3} className="text column">
                        <div className="columntitle">Minecraft Servers</div>
                        <div className="summaries">
                            I host a few Minecraft servers from my house using Docker containers. This setup lets my
                            friends join in and have fun together while keeping everything running smoothly and organized.
                        </div>
                    </Col>
                    <Col sm={3} className="column">
                        <img className="img-fluid rounded mx-auto d-block" src={jellyfin} alt=""/>
                    </Col>
                    <Col sm={3} className="text column">
                        <div className="columntitle">Private Media Server</div>
                        <div className="summaries">
                            I host a private media server that’s accessible on my LAN, and it is
                            configured for WAN access for me and a for a few friends and family, with SSL
                            encryption. The server contains all my owned media, including movies, music, and pictures.
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={3} className="column">
                        <img className="img-fluid rounded mx-auto d-block" src={cloud} alt=""/>
                    </Col>
                    <Col sm={3} className="text column">
                        <div className="columntitle">Private Cloud Server</div>
                        <div className="summaries">
                            I got rid of Google Drive, OneDrive, and all other cloud services! Now, I host my own private
                            cloud server from home with up to 4TB of storage in a RAID1 configuration to ensure my data
                            is protected. This server is accessible from any external network and secured with SSL
                            encryption.
                        </div>
                    </Col>
                    <Col sm={3} className="column">
                        <img className="img-fluid rounded mx-auto d-block" src={snake} alt=""/>
                    </Col>
                    <Col sm={3} className="text column">
                        <div className="columntitle">Snake Game(s)</div>
                        <div className="summaries">
                            I've programmed the original Snake game, adding some extra features, in Assembly, C++,
                            and Verilog. All versions of the game can be found on my GitHub page on the link below!
                        </div>
                    </Col>
                </Row>
              </Container>
          </AnimatedPage>
      </div>
  )
};

export default Projects;