import { motion } from "framer-motion";

const animations = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
      duration: 2
    }
  }
}

const AnimatedPage = ({ children }) => {
  return (
    <motion.div
      variants={animations}
      initial="hidden"
      animate="show"
      transition={{ duration: 1 }}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedPage;