import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import '../styles/Footer.css'
import {Col, Row} from "react-bootstrap";
import linkedin from "../images/linkedin.png"
import cathub from "../images/cathub.png"
import gear from "../images/gear.png"

function Footer() {
  return (
      <footer className="container-fluid">
          <Row className="footy">
              <Col sm={3}>
                Copyright &copy; Jesus.Contact 2021
              </Col>
              <Col md={5}>
                  <a className="btn btn-light btn-social mx-2" href="https://www.potentiamaxima.com" target="_blank"
                     rel="noopener noreferrer" aria-label="Potentia Maxima LLC"><img
                      src={gear} alt=""/></a>
                  <a className="btn btn-light btn-social mx-2" href="https://github.com/iesus-s" target="_blank"
                     rel="noopener noreferrer" aria-label="GitHub"><img
                      src={cathub} alt=""/></a>
                  <a className="btn btn-light btn-social mx-2" href="https://www.linkedin.com/in/jesussandoval4"
                     rel="noopener noreferrer" target="_blank" aria-label="LinkedIn"><img
                      src={linkedin} alt=""/></a>
              </Col>
              <Col sm={3}>
                  <a className="text-decoration-none me-3" href="#!">Privacy Policy</a>
                  <a className="text-decoration-none" href="#!">Terms of Use</a>
              </Col>
          </Row>
      </footer>
  );
}

export default Footer;
