// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contacttitle {
    text-align: center;
    font-family: 'Sanskrit Text', serif;
    font-weight: bold;
    font-size: xx-large;
    color: white;
    padding-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/styles/Contact.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mCAAmC;IACnC,iBAAiB;IACjB,mBAAmB;IACnB,YAAY;IACZ,oBAAoB;AACxB","sourcesContent":[".contacttitle {\n    text-align: center;\n    font-family: 'Sanskrit Text', serif;\n    font-weight: bold;\n    font-size: xx-large;\n    color: white;\n    padding-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
