import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import React from "react";
import NavBar from "./components/NavBar";
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";


function App() {
      // Detect the current route
  return (
      <div className="App">
        <BrowserRouter>
                <NavBar/>
                    <Routes>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/projects" element={<Projects/>}/>
                        <Route path="/contact" element={<Contact/>}/>
                    </Routes>
                <Footer/>
        </BrowserRouter>
      </div>
  );
}

export default App;
