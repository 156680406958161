// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbur {
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    padding-inline: 10px;
    font-size: large;
    z-index: 1; /* Make sure the content stays above the background */
}

.logo {
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 80px;
    color: white;
}

.active-link {
    color: blue;
    font-weight: bold;
    text-decoration: underline;
}

.items {
    padding-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/NavBar.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,YAAY;IACZ,iBAAiB;IACjB,yCAAyC;IACzC,oBAAoB;IACpB,gBAAgB;IAChB,UAAU,EAAE,qDAAqD;AACrE;;AAEA;IACI,kBAAkB;IAClB,yCAAyC;IACzC,iBAAiB;IACjB,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,0BAA0B;AAC9B;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":[".navbur {\n    text-decoration: none;\n    color: white;\n    font-weight: bold;\n    font-family: Arial, Helvetica, sans-serif;\n    padding-inline: 10px;\n    font-size: large;\n    z-index: 1; /* Make sure the content stays above the background */\n}\n\n.logo {\n    text-align: center;\n    font-family: Arial, Helvetica, sans-serif;\n    font-weight: bold;\n    font-size: 80px;\n    color: white;\n}\n\n.active-link {\n    color: blue;\n    font-weight: bold;\n    text-decoration: underline;\n}\n\n.items {\n    padding-bottom: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
