import React from "react";
import AnimatedPage from "../components/AnimatedPage";
import Container from "react-bootstrap/Container";
import '../styles/HomePage.css';
import jesus from '../images/profilepic.jpg'
import {Col, Row} from "react-bootstrap";

const HomePage = () => {

  return (
      <div className="homepage">
          <AnimatedPage>
              <div className="thehomepage">
                  Who Am I?
              </div>
              <Container>
                  <Row className="justify-content-center">
                      <Col md={4} className="column">
                          <img className="jesus img-fluid rounded mx-auto d-block" src={jesus} alt=""/>
                      </Col>
                      <Col md={4} className="column">
                          <div className="hometitle">
                              Jesus Angel Sandoval
                          </div>
                          <Container className="homesummary">
                              I am a passionate Computer Engineer, Web Developer, Project Engineer, and Entrepreneur.
                              My goal is to leverage and develop new skills to make a positive impact on the world.
                              As a lifelong learner, I strive for continuous improvement in all aspects of my
                              life, including my career, relationships with family and friends, and in my various endeavours.
                              Join me on this journey, and together we can make a difference!
                          </Container>
                      </Col>
                  </Row>
              </Container>
          </AnimatedPage>
      </div>
  )
};

export default HomePage;